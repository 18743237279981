import en from "/locales/en-US.yaml";
import nl from "/locales/nl-NL.yaml";

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'en',
    messages: {
      en,
      nl
    }
  }
})
